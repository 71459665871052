<template>
  <keep-alive>
    <v-card elevation="0" class="mx-2">
      <v-card-title>
        <v-row align-self="end">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
          <v-col class="text-right">
            <v-btn color="primary" dark class="mb-2 mr-2" @click="onRefresh">
              {{ $t('plan.refresh') }}
              <v-icon right dark>
                mdi-refresh
              </v-icon>
            </v-btn>
            <!-- <v-btn color="pink" dark class="mb-2 mr-2" @click="onReset">
              Reset [DEBUG]
              <v-icon right dark>
                mdi-debug
              </v-icon>
            </v-btn>-->
            <v-btn color="primary" dark class="mb-2" @click="onExport">
              {{ $t('export') }}
              <v-icon right dark>
                mdi-file-excel
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="ma-10">
        <v-btn-toggle
          v-model="machine"
          tile
          color="accent"
          group
          @change="onMachine"
        >
          <v-btn v-for="machine in machines" :key="machine.id" :value="machine">
            {{ machine.name }}
          </v-btn>
        </v-btn-toggle>
      </v-row>
      <v-tabs v-model="currentTab">
        <v-tab
          v-for="item in tabs"
          :key="item.value"
          :href="'#tab-' + item.value"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <div v-if="machine">
        <v-tabs-items v-model="currentTab">
          <v-tab-item value="tab-Jobs">
            <plan
              :machine="machine"
              :epoch="epoch"
              :search="search"
              @refreshRequired="epoch++"
            />
          </v-tab-item>
          <v-tab-item value="tab-Archived">
            <plan
              archived
              :machine="machine"
              :epoch="epoch"
              :search="search"
              @refreshRequired="epoch++"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
    <!-- <component :is="current" v-bind="params"></component> -->
  </keep-alive>
</template>

<script>
import plan from '@/views/dashboard/components/plan/planCard';
import MachineService from '@/services/MachineService.js';
import PlanService from '@/services/PlanService.js';

export default {
  components: { plan },
  data: function() {
    return {
      currentTab: 'tab-Jobs',

      machine: null,
      machines: null,
      epoch: 1,
      search: ''
    };
  },
  computed: {
    tabs() {
      return [
        { text: this.$t('plan.jobs'), value: 'Jobs' },
        { text: this.$t('archived'), value: 'Archived' }
      ];
    }
  },
  watch: {},
  async mounted() {
    await this.loadMachines();
  },
  methods: {
    async loadMachines() {
      this.loading = true;
      try {
        this.machines = await MachineService.getMachines();
        console.log('machines:', this.machines);
        if (this.machines.length > 0) {
          this.machine = this.machines[0];
        }
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    onMachine(evt) {
      console.log(evt);
    },
    async onRefresh() {
      await PlanService.refresh();
      this.epoch++;
    },
    async onReset() {
      await PlanService.reset();
      this.epoch++;
    },
    async onExport() {
      const archived = this.currentTab == 'tab-Archived';
      await PlanService.excel(this.search ? this.search : '', archived);
    }
  }
};
</script>

<style></style>
